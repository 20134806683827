<template>
	<div class="login-container">
		<el-form class="formbox" :model="ruleForm" :rules="rules" ref="ruleForm">
			<el-form-item>
				<h3>裁判文书后台管理系统</h3>
			</el-form-item>
			<el-form-item prop="username">
				<el-input class="userinput" v-model="ruleForm.username" prefix-icon="el-icon-user" placeholder="请输入账号"
					maxlength="12"></el-input>
			</el-form-item>
			<el-form-item prop="password">
				<el-input class="userinput" v-model="ruleForm.password" prefix-icon="el-icon-unlock" placeholder="请输入密码"
					show-password maxlength="16"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button :loading="loading" type="primary" style="width:100%;height:50px;margin-top:30px;"
					@click.native.prevent="handleLogin('ruleForm')">登录</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { login } from '@/api/login'
export default {
	name: 'Login',
	data() {
		return {
			ruleForm: {
				username: '',
				password: ''
			},
			loading: false,
			rules: {
				username: [
					{ required: true, message: '请输入账号', trigger: 'blur' }
					// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				],
				password: [
					{ required: true, message: '请输入密码', trigger: 'blur' }
				]
			}
		}
	},
	watch: {

	},
	methods: {
		// 登录请求
		handleLogin(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					const res = await login({ ...this.ruleForm })
					if (res.code === 200) {
						this.$message({
								showClose: true,
								message: '登录成功',
								type: 'success'
							});
							sessionStorage.setItem('username', res.data.username)
							sessionStorage.setItem('token', res.data.token)
							this.$router.push('/home');
					} else {
						this.$message({
							showClose: true,
							message: '登录失败！' + res.msg,
							type: 'error'
						});
					}
				} else {
					// console.log('error submit!!');
					return false;
				}
			});

			// this.$router.push('/home')
		}
	}
}
</script>

<style lang="scss" scoped>
.formbox .el-input__inner {
	padding-bottom: 20px;
	padding-top: 20px;
	height: 50px;
	line-height: 50px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background-color: rgba(0, 0, 0, .1);
	color: #ffffff;
}

.login-container {
	width: 100%;
	height: 100vh;
	// background-color: #283443;
	background-color: var(--theme-color);
	position: relative;

	.formbox {
		width: 500px;
		height: 500px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -250px;
		margin-left: -250px;
		padding: 20px;

		h3 {
			// color: #eee;
			color: var(--custom-color);
			font-size: 26px;
			text-align: center;
			margin-bottom: 20px;
		}

		.userinput {
			margin-top: 20px;
		}
	}
}
</style>
