import axios from 'axios'

// 引入路由对象
import router from "../router"
import {
  Notification,
  MessageBox
} from 'element-ui';

// 创建axios实例
// const baseURL = 'http://localhost:8087/api';
const baseURL = 'https://data.wantongshiyi.com/api';
const service = axios.create({
  baseURL,
  // timeout: 20000 // 请求超时时间
}); 

// 给axios添加请求拦截器：发送请求时，还没到后端，就把请求给拦截下来
service.interceptors.request.use((config) => {
  // console.log(config);
  // config.headers.Authorization = sessionStorage.token
  config.headers['token'] = sessionStorage.getItem('token'); // 让每个请求携带自定义token 请根据实际情况自行修改
  //     }
  config.headers['Content-Type'] = 'application/json';
  return config
}, (err) => {
  return Promise.reject(err)
})


// 处理后端返回的报错信息：后端返回信息时，还没返回到前端，就把返回信息给拦截下来
// 给axios添加响应拦截器，有两个箭头函数，成功进入第一个箭头函数，失败进入第二个箭头函数
service.interceptors.response.use((response) => {
   // console.log("=====",response);
   if (response.data.code === 400) {
    
     Notification.error('登录已过期，请重新登录', '警告', {
           confirmButtonText: '确定',
           callback: action => {
               router.push('/login')
           }
         });
		  sessionStorage.clear();
		  setTimeout(() => {
		    router.push('/login')
		  }, 3000);
   }
  // 判断改账号是否在其他地方登录
  if(response.data.code === 40100) {
    Notification.error({
      title: '该账号已在别处登陆,请重新登陆!',
      duration: 5000
    });
    sessionStorage.clear();
    setTimeout(() => {
      router.push('/')
    }, 3000);
    // router.push('/')
  }
  // 响应成功
  const code = response.status;
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.data.msg
      });
      return Promise.reject('error');
    } else {
      return response.data;
    }

}, (err) => {
	// console.log("err===>"+err)
      let code = 0;
    try {
      code = err.response.data.status;
      
    } catch (e) {
      if (err.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 5000
        });
        // console.log('超时')
        return Promise.reject(err);
      } 
    }
  // 响应失败（后端返回了报错信息）
  if (err.response.data.code === 400) {
    // console.log('401')
    // Notification.error('登录已过期，请重新登录', '警告', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //           router.push('/login')
    //       }
    //     });
    ElMessageBox.alert('登录已过期，请重新登录', '警告', {
      confirmButtonText: '确定',
      callback: (action) => {
          router.push('/login')
      },
    })
  } else {
    return Promise.reject(err)
  }
})

export default service;