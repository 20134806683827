import request from '@/utils/request'
// 查询任务列表
export function queryTaskList() {
    return request({
      url: '/tasks/query-list',
      method: 'get'
    });
}

// 查询更新
export function queryUpdates(data) {
  return request({
    url: '/tasks/query-updates',
    method: 'get',
    params: data
  });
}