<template>
  <el-card shadow="hover">
        <el-upload
        :headers="{Token:token}"
          class="upload-demo"
          :action="$store.state.uploadUrl"
          :on-success="handleSuccess"
          :on-error="handleError"
          multiple
          auto-upload
          :file-list="fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-card>
 
</template>

<script>
import {sourceDataList} from '../api/dataList'
export default {
  data() {
    return {
      fileList: [],
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      value: "",
      token: null
    };
  },
  methods: {
    handleSelectionChange(val) {
      //   this.multipleSelection = val;
    },
    // 文件上传失败
    handleError(err) {
      console.log(err)
    },
    // 文件上传成功
    handleSuccess(res, file) {
      // console.log(res)
      if(res.code !== 200) {
        this.$message({
              type: "error",
              message: '上传失败！' + res.msg,
            });
        this.fileList = this.fileList.map(item => {
          return item.name !== file.name
        })
      }
      // console.log(file)
    },
    // 获取源文件列表
    async getData() {
      const res = await sourceDataList({search: this.value});
      // console.log(res);
      if(res.code === 200) {
        this.tableData = res.data;
      }
    }
  },
  created() {
    this.token = sessionStorage.getItem('token')
  }
  
};
</script>

<style >
 .el-row {
    margin-bottom: 20px;
    }
</style>