<template>
  <el-row :gutter="24">
    <el-col :span="24">
      <el-card shadow="hover">
        <template>
          <el-select size="small" v-model="searchInfo.year" clearable placeholder="请选择">
            <el-option
              v-for="item in yearsData"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select> 
        </template>
        <el-button type="primary" size="small" :loading="loading1" @click="initCivilData"
          >获取民事数据</el-button
        >
        <!-- <el-button type="primary" size="small" @click="clean"
          >清空面板数据</el-button
        > -->
        <!-- <el-button type="primary" size="small"  @click="prepareDown"
          >下载面板数据 -->
          <!-- <a :href="$store.state.fileUrl + 'document/面板数据2/民事面板数据.dta'"
            >下载面板数据</a> -->
            <!-- </el-button> -->
      </el-card>
    </el-col>
    <el-col :span="24">
      <el-card shadow="hover">
        <h4>
          民事案件 <span>共计：{{ civilTotal }}行</span>
        </h4>
        <el-table
          v-loading="loading1"
          :data="tableData"
          element-loading-text="请求中，请等待"
          element-loading-spinner="el-icon-loading"
          height="650"
          border
          style="width: 100%"
        >
          <!-- <el-table-column v-if="civilHeader.length > 0" type="index" fixed="left"> </el-table-column> -->
          <!-- <el-table-column label="id" prop="id"></el-table-column> -->
          <el-table-column label="年份" prop="year"></el-table-column>
          <el-table-column label="月份" prop="month"></el-table-column>
          <el-table-column label="法院" prop="courtName"></el-table-column>
          <el-table-column label="行政区" prop="code"></el-table-column>
          <el-table-column label="省" prop="province"></el-table-column>
          <el-table-column label="市" prop="city"></el-table-column>
          <el-table-column label="区/县" prop="county"></el-table-column>
          <el-table-column label="案件总数" prop="total">
            <template slot-scope="scope">
               <el-tag type="success"  v-if="scope.row.total">{{ scope.row.total }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="一类" prop="firstKeywords">
            <template slot-scope="scope">
              <div v-show="scope.row.firstKeywords">
                <el-tag type="success">{{ scope.row.firstKeywords }}</el-tag>
                &nbsp; &nbsp;
                <el-button
                  @click="showMore(scope.row.year, scope.row.month, scope.row.code, scope.row.courtName, 1)"
                  size="mini"
                  type="text"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="二类" prop="secondKeywords">
            <template slot-scope="scope">
              <div v-show="scope.row.secondKeywords">
                <el-tag type="success">{{ scope.row.secondKeywords }}</el-tag>
                &nbsp; &nbsp;
                <el-button
                  @click="showMore(scope.row.year, scope.row.month, scope.row.code, scope.row.courtName, 2)"
                  size="mini"
                  type="text"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="三类" prop="thirdKeywords">
            <template slot-scope="scope">
              <div v-show="scope.row.thirdKeywords">
                <el-tag type="success">{{ scope.row.thirdKeywords }}</el-tag>
                &nbsp; &nbsp;
                <el-button
                  @click="showMore(scope.row.year, scope.row.month, scope.row.code, scope.row.courtName, 3)"
                  size="mini"
                  type="text"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="四类" prop="fourthKeywords">
            <template slot-scope="scope">
              <div v-show="scope.row.thirdKeywords">
                <el-tag type="success">{{ scope.row.fourthKeywords }}</el-tag>
                &nbsp; &nbsp;
                <el-button
                  @click="showMore(scope.row.year, scope.row.month, scope.row.code, scope.row.courtName, 4)"
                  size="mini"
                  type="text"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>
          
          <!-- <el-table-column label="操作" prop="total">
            <template slot-scope="scope">
              <el-button @click="showMore(scope)" size="mini">查看</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <div style="display:flex;justify-content:flex-end;">
          <el-pagination
          layout="prev, pager, next"
          :total="civilTotal"
          :page-size.sync="searchInfo.pageSize"
          :current-page.sync="searchInfo.pageNum"
          @current-change="handleCurrentPage">
        </el-pagination>
        </div>
      </el-card>
    </el-col>
    <el-col :span="24">
      <el-drawer
        :title="levelShowName + '关键字详情'"
        :visible.sync="drawer"
        direction="rtl"
        border
      >
        <el-table :data="detailData" v-loading="loading2">
          <el-table-column
            property="keywordName"
            label="关键字"
          ></el-table-column>
          <el-table-column
            property="total"
            label="总数"
            width="150"
          ></el-table-column>
        </el-table>
      </el-drawer>
    </el-col>

    <el-dialog 
    :visible.sync="downDialogVisible"
    width="30%"
    title="提示">
    <template #default>
      <div class="row tips">
      最新的数据导出在{{downDialogInfo?.completeTime}},如果继续下载可以点击继续按钮,
      如果要导出最新的数据,请点击导出.或者查看导出任务列表
      </div>
      <div class="row button">
         <el-button type="text" @click="showTasks">查看导出任务&nbsp;></el-button>
      </div>
     
    </template>
    <template #footer>
      <div class="button-container">
        <el-button :loading="exporting" @click="createExportTask">导出</el-button>
        <el-button type="primary" @click="downLoad">继续</el-button>
      </div>
    </template>
    </el-dialog>

    <tasks-show-dialog ref="tasksShowDialog"></tasks-show-dialog>
  </el-row>
</template>renwu
  
  <script>
import {
  civilData,
  cleanCivilData,
  getKeywordsData,
  getMinshiDataByYear,
  queryLatestExport,
  exportAsync,
  getCivilKeywordsData
} from "@/api/dataList";
import TasksShowDialog from "./TasksShowDialog.vue";

const pageInitData = {
  pageNum: 1,
  pageSize: 100
}

export default {
  components: {
    TasksShowDialog
  },
  data() {
    return {
      level: 1,
      loading2: false,
      hasTasksShow: false,
      exporting: false,
      downDialogInfo:{},
      downDialogVisible: false,
      tableData: [],
      searchInfo: {
        year: null,
        ...pageInitData
      },
      civilHeader: [], // 民事表头
      civilTotal: 0,
      loading1: false,
      detailData: [],
      drawer: false,
      isExist: true,
      yearsData: [
        1985,1986,1987,1988,1989,1990,1991,1992,1993,1994,1995,1996,1997,1998,1999,2000,2001,2002,2003,2004,
        2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021
      ], // 年份数据
    };
  },
  computed: {
    levelShowName() {
      let res='';
      switch(this.level) {
        case 1:
          res = "一级";
          break;
        case 2:
          res = "二级";
          break;
        case 3:
          res = "三级";
          break;
        case 4:
          res = "四级"
          break;
      }
      return res;
    }
  },
  methods: {
    async initCivilData() {
      this.resetSearchData();
      await this.getCivilData();
    },
    handleCurrentPage(currentPage) {
      this.searchInfo.pageNum = currentPage;
      this.getCivilData();
    },
    resetSearchData() {
      const year = this.searchInfo.year;
      this.searchInfo = {
        year,
        ...pageInitData
      }
    },
    showTasks() {
      this.$refs.tasksShowDialog.open();
      // this.hasTasksShow = true
    },
    createExportTask() {
      this.exporting = true
      console.log("==测试导出")
      exportAsync().then(res=>{
        this.exporting = false;
        this.closeDownDialog();
        return this.$confirm('已创建导出任务,是否查看导出任务列表?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
      }, err=>{this.exporting = false})
      .then(()=>{
        this.$refs.tasksShowDialog.open();
        console.log("==查看导出任务列表")
      })
    },
    prepareDown() {
      this.loading1 = true;
      queryLatestExport().then(res=>{
        this.downDialogInfo = res.data;
        this.loading1 = false;
        this.downDialogVisible = true;
      }).catch(e=>{
        this.loading1 = false;
      })
    },
    // 民事
    async getCivilData() {
     if (this.searchInfo.year) {
      this.loading1 = true;
      //const res = await civilData(this.searchInfo);
      const res = await getMinshiDataByYear(this.searchInfo);
      console.log(res);
      if (res.code === 200 && res.data) {
        this.tableData = res.data.list;
        // this.civilHeader = res.data.header;
        this.civilTotal = res.data.total;
      }
      this.loading1 = false;
     } else {
      this.$message('请先选择需要搜索的年份！');
     }
    },
    closeDownDialog() {
      this.downDialogVisible = false;
    },
    downLoad() {
      // if (this.tableData.length > 0) {
        const fileUrl =
          this.$store.state.fileUrl + this.downDialogInfo.extendInfo; // 文件的URL地址
        window.open(fileUrl);
      // }
      this.closeDownDialog();
    },
    // 清空面板数据
    async clean() {
      this.$confirm("确定清空民事面板数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.searchInfo.year = null;
          this.resetSearchData();
          this.tableData = [];
          this.civilTotal = 0;
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "操作失败",
          // });
        });
    },
    // 查看更多
    async showMore(year, month, regionalCode, courtName, level) {
 
      this.drawer = true;
      this.loading2 = true;
      try {
        this.level = level;
        let info = { year, month, regionalCode, courtName, level};
        const res = await getCivilKeywordsData(info);
        // console.log(res);
        if (res.code === 200) {
          this.detailData = res.data;
        }
      } finally {
        this.loading2 = false;
      }
    },

    handleClose() {},
    // async years() {
    //   const res = await getYears({ type: 1 });
    //   // console.log(res);
    //   if(res.code === 200) {
    //     this.yearsData = res.data
    //   }
    // },
  },
  created() {
    // if(this.searchInfo.year === null ) {
    //   this.$message('请先选择需要搜索的年份！');
    // }
    // console.log(this.civilHeader)
    // this.getCivilData();
    // this.getCriminalData();
  },
};
</script>
  
  <style scoped>
.el-drawer__body {
  padding: 20px;
}
a {
  text-decoration: dashed;
  color: white;
}
.button-container {
  display: flex;
  justify-content: space-evenly;
}
.row.tips {
  /* word-spacing: 5px; */
  letter-spacing: 2px;
  line-height: 2;
}
.row.button {
  text-align: right;
}
</style>
