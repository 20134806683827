<template>
  <div>
    <el-dialog 
    title="导出任务列表" 
    :visible.sync="dialogVisible"
    @open="onOpen"
    @close="onClose">
      <el-table
      :data="tableData"
      style="width: 100%">
        <el-table-column
          prop="createTime"
          label="创建时间">
        </el-table-column>
        <!-- <el-table-column
          prop="updateTime"
          label="更新时间">
        </el-table-column> -->
        <el-table-column
          prop="completeTime"
          label="完成时间">
        </el-table-column>
        <el-table-column
          prop="typeOutput"
          label="类型">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span>{{scope.row.statusOutput}}</span>
            <i v-if="scope.row.status==1" class="el-icon-loading"></i>
            <i v-else-if="scope.row.status==2" class="el-icon-loading"></i>
            <i v-else-if="scope.row.status==3" class="el-icon-success"></i>
            <i v-else-if="scope.row.status==4" class="el-icon-error"></i>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <a v-if="scope.row.extendInfo" :href="$store.state.fileUrl + scope.row.extendInfo" target="_blank">下载</a>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryTaskList,
  queryUpdates
  } from '../api/tasks.js'
export default {
  data() {
    return {
      tableData: [
      ],
      token: null,
      lastUpdateTime: null,
      dialogVisible: false,
      interval : null
    };
  },
  watch: {
    dialogVisible: {
      handler: function(newValue, oldValue) {

      },
      immediate: true
    }
  },
  methods: {
    onOpen() {
      if(!this.interval) {
        this.init()
      }
    },
    onClose() {
      if(this.interval) {
        clearInterval(this.interval)
        this.interval = null
        console.log("==停止轮询任务更新")
      }
    },
    open() {
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    },
    async init() {
      await this.loadData();
      await this.startQueryUpdates();
    },
    async loadData() {
      let res = await queryTaskList()
      this.tableData = res.data.taskList
      this.lastUpdateTime = res.data?.lastUpdateTime
    },
    async startQueryUpdates() {
      const _this = this;
      this.interval = setInterval(() => {
        queryUpdates({lastUpdateTime: _this.lastUpdateTime}).then(res=>{
          console.log("==queryUpdates: ", res.data)
          if(!(res.data)) return;
          if(res.data.lastUpdateTime) this.lastUpdateTime = res.data.lastUpdateTime;
          const updateTaskList = res.data.taskList;
          for(let updateTask of updateTaskList) {
            const index = this.tableData.findIndex(item=>item.id==updateTask.id);
            if(index > -1) {
              this.tableData.splice(index, 1, updateTask);
            }
          }
        })
      }, 5000);
      console.log("==开始轮询任务更新")
    }
  },
  created() {
    this.token = sessionStorage.getItem('token')
    // this.init()
  },
  beforeDestroy() {
    if(this.interval) {
      clearInterval(this.interval)
    }
  }
  
};
</script>

<style >
 .el-row {
    margin-bottom: 20px;
    }
</style>