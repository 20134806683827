import request from "@/utils/request";
// 获取源文件列表
export function sourceDataList(params) {
  return request({
    url: "/file/list",
    method: "get",
    params,
  });
}
// 删除源文件
export function deleteFiles(data) {
  return request({
    url: "/file/remove",
    method: "delete",
    data,
  });
}

// 测算数据
export function MeasurementData(data) {
  return request({
    url: "/new/original/singleThreaded/processing/data",
    method: "post",
    data,
  });
}
// 民事测算数据
export function civilData(params) {
  return request({
    url: "/new/original/civil/panel/data",
    method: "get",
    params,
  });
}
// 刑事测算数据
export function criminalData(params) {
  return request({
    url: "/criminal-final-data/query-panel",
    method: "get",
    params,
  });
}
//清空面板数据
// export function cleanData(data) {
//   return request({
//     url: "/new/original/clean/processing/data",
//     method: "post",
//     data,
//   });
// }
//清空民事面板数据
export function cleanCivilData(data) {
  return request({
    url: "/new/original/clean/civil/processing/data",
    method: "post",
    data,
  });
}
//清空刑事面板数据
export function cleanCriminalData(data) {
  return request({
    url: "/new/original/clean/criminal/processing/data",
    method: "post",
    data,
  });
}

//查看关键字
export function getKeywordsData(params) {
  return request({
    url: "/new/original/keywords/data",
    method: "get",
    params,
  });
}

//查看刑事关键字
export function getCriminalKeywordsData(params) {
  return request({
    url: "/criminal-final-data/query-level-data",
    method: "get",
    params,
  });
}

//查看民事关键字
export function getCivilKeywordsData(params) {
  return request({
    // url: "/new/original/keywords/data",
    url: "/civil-final-data/query-level-data",
    method: "get",
    params,
  });
}

//数据预处理
export function pretreatmentData(data) {
  return request({
    url: "/step/original",
    method: "post",
    data,
  });
}
//刑事一审
export function pretreatmentData2(data) {
  return request({
    url: "/step/original/criminal/first/instance",
    method: "post",
    data,
  });
}
//民事案件
export function pretreatmentData3(data) {
  return request({
    url: "/step/original/civil/case",
    method: "post",
    data,
  });
}
//民事一审
export function pretreatmentData4(data) {
  return request({
    url: "/step/original/civil/first/instance",
    method: "post",
    data,
  });
}
// 获取面板数据年份
export function getYears(params) {
  return request({
    url: "/new/original/panel/data/by/year",
    method: "get",
    params,
  });
}

// 获取民事面板数据
export function getMinshiDataByYear(params){
    return request({
    url: "/civil-final-data/query-panel",
    method: "get",
    params,
  });
}


// 民事-存库
export function saveDatabase(data){
  return request ({
    url:'/civil-data/importExcelData',
    method:"Post",
    data
  })
}

// 查询最新民事导出
export function queryLatestExport(){
  return request ({
    url:'/civil-final-data/query-latest-export',
    method:"Get"
  })
}

// 创建异步导出任务
export function exportAsync(){
  return request ({
    url:'/civil-final-data/export-async',
    method:"Get"
  })
}