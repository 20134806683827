<template>
  <div class="home" style="position: relative;">
    <el-dropdown  @command="loginOut" style="position: absolute; right: 10px; top: 10px; z-index: 11111111">
      <span class="el-dropdown-link" >
        {{username}}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> 
    <el-tabs v-model="activeName" type="border-card" @tab-click="clickTab">
      <!-- <el-tab-pane name="upload" label="上传">
        <UploadVue ref="upload"></UploadVue>
      </el-tab-pane> -->
      <el-tab-pane name="file" label="文件列表">
        <FileVue ref="file"></FileVue>
      </el-tab-pane>
      <el-tab-pane name="dataShow" label="民事面板数据">
        <DataShowVue ref="dataShow" />
      </el-tab-pane>
      <el-tab-pane name="criminal" label="刑事面板数据">
        <DataCriminal ref="criminal" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import UploadVue from "@/components/Upload.vue";
import FileVue from "@/components/File.vue";
import DataShowVue from "@/components/DataShow.vue";
import DataCriminal from "@/components/DataCriminal.vue";
export default {
  name: "HomeView",
  components: {
    UploadVue,
    FileVue,
    DataShowVue,
    DataCriminal,
  },
  data() {
    return {
      activeName: "file",
      username: null
    };
  },
  methods: {
    clickTab(val, event) {
      // console.log(this.activeName)
      if (this.activeName === "file") {
        this.$refs.file.getData();
      } else if (this.activeName === "dataShow") {
        // this.$refs.dataShow.years();
        // this.$refs.dataShow.getCivilData();
      } else if (this.activeName === "criminal") {
        // this.$refs.criminal.years();
        // this.$refs.criminal.getCriminalData();
      }
    },
    loginOut() {
      // console.log(111)
      sessionStorage.clear();
      this.$router.push('/')
    }
  },
  created() {
   this.username = sessionStorage.getItem('username')
  }
};
</script>
<style scoped>
.el-tabs__content {
  min-height: 500px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}

</style>
