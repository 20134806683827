import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // uploadUrl: 'http://192.168.0.111:8087/api/file/upload',
    uploadUrl: 'https://data.wantongshiyi.com/api/file/upload',
    fileUrl: 'https://cos.wantongshiyi.com/'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
