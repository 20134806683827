import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginVue from "../views/Login.vue";
import {
  Notification,
  Message
} from 'element-ui';
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
	redirect: "/login",
    component: LoginVue,
  },
  {
    path: "/login",
    name: "login",
    component: LoginVue,
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    beforeEnter: async (to, from, next) => {
      // 获取本地的 token
      const token = sessionStorage.token;
      if (token) {
        // 本地有 token
        // const data = await api.users.getLogin({
        //   account: sessionStorage.account,
        //   password: sessionStorage.password,
        // });
        // if (data.code) {
        //   // 如果后端返回了用户信息，可以将用户信息保存在 Vuex 或本地存储中
        //   store.commit("SET_USER_INFO", data.data);
        //   // token 验证有效，允许用户进入权限页面
        next();
        // }
      } else {
        // 本地没有 token
        // ElementUI.Message({
        //   showClose: true,
        //   message: '这是一条消息提示'
        // });
        // Notification.error({
        //   title: '你还未登录，请先登录!',
        //   duration: 5000
        // });
        Message({
          showClose: true,
          message: '你还未登录，请先登录！'
        });
        // alert("你还未登录，请先登录"); // 可以根据项目的 UI 框架来设置弹框提示
        next("/");
      }
    }
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = new VueRouter({
  routes,
});

export default router;
