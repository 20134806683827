<template>
  <el-row :gutter="24">
    <el-col :span="24">
      <el-card shadow="hover">
        <template>
          <el-select size="small" v-model="searchInfo.year" clearable placeholder="请选择">
            <el-option
              v-for="item in yearsData"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select> 
        </template>
        <el-button type="primary" size="small" :loading="loading2" @click="initCriminalData"
          >获取刑事数据</el-button
        >
        <!-- <el-button type="primary" size="small" @click="clean"
          >清空面板数据</el-button
        > -->
        <!-- <el-button
          type="primary"
          size="small"
          :disabled="isExist"
          @click="downLoad"
        >
          下载面板数据 -->
          <!-- <a
            :href="$store.state.fileUrl + 'document/面板数据2/刑事面板数据.dta'"
            >下载面板数据</a
          > -->
        <!-- </el-button> -->
      </el-card>
    </el-col>
    <el-col :span="24">
      <el-card shadow="hover">
        <h4>
          刑事案件 <span>共计：{{ criminalTotal }}行</span>
        </h4>
        <el-table
          :data="tableData2"
          v-loading="loading2"
          element-loading-text="请求中，请等待"
          element-loading-spinner="el-icon-loading"
          height="650"
          border
          style="width: 100%"
        >
          <el-table-column label="年份" prop="year"></el-table-column>
          <el-table-column label="月份" prop="month"></el-table-column>
          <el-table-column label="法院" prop="courtLevel"></el-table-column>
          <el-table-column label="行政区" prop="regionalCode"></el-table-column>
          <el-table-column label="省" prop="province"></el-table-column>
          <el-table-column label="市" prop="city"></el-table-column>
          <el-table-column label="区/县" prop="county"></el-table-column>
          <el-table-column label="案件总数" prop="totalNumber">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.totalNumber">{{
                scope.row.totalNumber
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="一类" prop="firstKeywordsNum">
            <template slot-scope="scope">
              <div v-show="scope.row.firstKeywordsNum">
                <el-tag type="success">{{ scope.row.firstKeywordsNum }}</el-tag>
                &nbsp; &nbsp;
                <el-button
                  @click="showMore({...scope.row,keywordsLevel:1})"
                  size="mini"
                  type="text"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="二类" prop="secondKeywordsNum">
            <template slot-scope="scope">
              <div v-show="scope.row.secondKeywordsNum">
                <el-tag type="success">{{
                  scope.row.secondKeywordsNum
                }}</el-tag>
                &nbsp; &nbsp;
                <el-button
                  @click="showMore({...scope.row,keywordsLevel:2})"
                  size="mini"
                  type="text"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="一类案由" prop="firstCauseNum">
            <template slot-scope="scope">
              <div v-show="scope.row.firstCauseNum">
                <el-tag type="success">{{ scope.row.firstCauseNum }}</el-tag>
                &nbsp; &nbsp;
                <el-button
                  @click="showMore({...scope.row,causeLevel:1})"
                  size="mini"
                  type="text"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="二类案由" prop="secondCauseNum">
            <template slot-scope="scope">
              <div v-show="scope.row.secondCauseNum">
                <el-tag type="success">{{ scope.row.secondCauseNum }}</el-tag>
                &nbsp; &nbsp;
                <el-button
                  @click="showMore({...scope.row,causeLevel:2})"
                  size="mini"
                  type="text"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column label="操作" prop="total">
            <template slot-scope="scope">
              <el-button @click="showMore(scope)">查看</el-button>
            </template>
          </el-table-column> -->
        </el-table>

        <div style="display:flex;justify-content:flex-end;">
          <el-pagination
          layout="prev, pager, next"
          :total="criminalTotal"
          :page-size.sync="searchInfo.pageSize"
          :current-page.sync="searchInfo.pageNum"
          @current-change="handleCurrentPage">
          </el-pagination>
        </div>
      </el-card>
    </el-col>
    <el-col :span="24">
      <el-drawer
        :title="title + '详情'"
        :visible.sync="drawer"
        direction="rtl"
        border
      >
        <el-table :data="detailData" v-loading="detailLoading">
          <el-table-column
            property="keywordName"
            :label="title"
          ></el-table-column>
          <el-table-column
            property="total"
            label="总数"
            width="150"
          ></el-table-column>
        </el-table>
      
      </el-drawer>
    </el-col>
  </el-row>
</template>

<script>
import { criminalData, cleanCriminalData, getKeywordsData, getYears, getCriminalKeywordsData } from "@/api/dataList";

const pageInitData = {
  pageNum: 1,
  pageSize: 100
}
export default {
  data() {
    return {
      tableData2: [],
      searchInfo: {
        year: null,
        ...pageInitData
      },
      criminalHeader: [],
      criminalTotal: 0,
      loading2: false,
      detailLoading: false,
      detailData: [],
      drawer: false,
      title: "关键字",
      isExist: true,
      yearsData: [
        1985,1986,1987,1988,1989,1990,1991,1992,1993,1994,1995,1996,1997,1998,1999,2000,2001,2002,2003,2004,
        2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021
      ], // 年份数据
    };
  },
  methods: {
    async initCriminalData() {
      this.resetSearchData();
      await this.getCriminalData();
    },
    handleCurrentPage(currentPage) {
      this.searchInfo.pageNum = currentPage;
      this.getCriminalData();
    },
    resetSearchData() {
      const year = this.searchInfo.year;
      this.searchInfo = {
        year,
        ...pageInitData
      }
    },
    // 刑事
    async getCriminalData() {
      if (this.searchInfo.year) {
        this.loading2 = true;
        const res = await criminalData(this.searchInfo);
        console.log(res);
        this.loading2 = false;
        if (res.code === 200 && res.data) {
          this.tableData2 = res.data.list;
          this.criminalTotal = res.data.total;
          
        }
      } else {
        this.$message('请先选择需要搜索的年份！');
      }
    
    },
    downLoad() {
      if (this.tableData2.length > 0) {
        const fileUrl =
          this.$store.state.fileUrl + "document/面板数据1/刑事面板数据.xlsx"; // 文件的URL地址
        window.open(fileUrl);
      }
    },
    // 查看更多
    async showMore({year,month,regionalCode,courtLevel,keywordsLevel,causeLevel}={}) {
      if (keywordsLevel==1) {
        this.title = "一类关键字";
      } else if (keywordsLevel ==2) {
        this.title = "二类关键字";
      } 
      if (causeLevel==1) {
        this.title = "一类案由";
      } else if (causeLevel ==2) {
        this.title = "二类案由";
      } 

      // console.log(val1);
      this.drawer = true;
      this.detailLoading = true
      let info = { year,month,regionalCode,courtLevel, keywordsLevel, causeLevel};
      const res = await getCriminalKeywordsData(info);
      this.detailLoading = false
      console.log(res);
      if (res.code === 200) {
        this.detailData = res.data;
      }
    },
    // 清空面板数据
    async clean() {
      this.$confirm("确定清空刑事面板数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.searchInfo.year = null;
          this.resetSearchData();
          this.tableData2 = [];
          this.criminalTotal = 0;
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "操作失败",
          // });
        });
    },
    async years() {
      const res = await getYears({ type: 2 });
      console.log(res);
      if(res.code === 200) {
        this.yearsData = res.data
      }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: dashed;
  color: white;
}
</style>