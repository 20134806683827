<template>
  <el-row :gutter="24">
    <el-col :span="24">
      <el-card shadow="hover">
        <!-- <h4>民事案件</h4> -->
        <el-row :gutter="20">
          <el-col :span="6"
            ><el-input
              size="small"
              placeholder="请输入内容"
              v-model="value"
              clearable
            >
            </el-input
          ></el-col>
          <el-col :span="6">
            <el-button type="primary" size="small" @click="getData"
              >搜索</el-button
            >
            <!-- <el-button type="primary" size="small" @click="Measurement"
              >测算</el-button
            > -->
            <!-- <el-dropdown @command="handleCommand" style="margin: 0 20px">
              <el-button type="primary" size="small">
                测算<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">数据预处理</el-dropdown-item>
                <el-dropdown-item command="b">刑事一审</el-dropdown-item>
                <el-dropdown-item command="c">民事案件</el-dropdown-item>
                <el-dropdown-item command="d">民事一审</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
            <!-- <el-button type="danger" size="small" @click="deleteFile">删除</el-button> -->
            <!-- <el-button type="primary" size="small" @click="saveDatabase"
              >民事-存库</el-button
            > -->
            </el-col
          >
          <span>总数： {{ total }}</span>
        </el-row>
        <el-table
          :data="tableData"
          height="700"
          border
          v-loading="loading"
          element-loading-text="测算中，请等待"
          element-loading-spinner="el-icon-loading"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="id" label="ID" width="55"> </el-table-column>
          <el-table-column prop="fileName" label="名称" width="180">
          </el-table-column>
          <el-table-column prop="uploadTime" label="上传时间" width="180">
          </el-table-column>
          <el-table-column prop="processingTime" label="测算时间" width="180">
          </el-table-column>
          <el-table-column prop="processingTime" label="处理状态" width="180">
            <template slot-scope="scope">
              <span>{{
                state[ scope.row.status]
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="源文件地址">
            <template slot-scope="scope">
              <a
                v-if="scope.row.fileUrl && scope.row.fileUrl !== '无'"
                :href="$store.state.fileUrl + scope.row.fileUrl"
                ><span>{{ $store.state.fileUrl }}</span
                ><span>{{ scope.row.fileUrl }}</span></a
              >
              <span v-else>{{ scope.row.fileUrl }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数据预处理-民事案件">
            <template slot-scope="scope">
              <a
                v-if="scope.row.firstStepUrl && scope.row.firstStepUrl !== '无'"
                :href="$store.state.fileUrl + scope.row.firstStepUrl"
                ><span>{{ $store.state.fileUrl }}</span
                ><span>{{ scope.row.firstStepUrl }}</span></a
              >
              <span v-else>{{ scope.row.firstStepUrl }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数据预处理-刑事案件">
            <template slot-scope="scope">
              <a
                v-if="scope.row.twoStepUrl && scope.row.twoStepUrl !== '无'"
                :href="$store.state.fileUrl + scope.row.twoStepUrl"
                ><span>{{ $store.state.fileUrl }}</span
                ><span>{{ scope.row.twoStepUrl }}</span></a
              >
              <span v-else>{{ scope.row.twoStepUrl }}</span>
            </template>
          </el-table-column>
          <el-table-column label="刑事一审">
            <template slot-scope="scope">
              <a
                v-if="scope.row.threeStepUrl && scope.row.threeStepUrl !== '无'"
                :href="$store.state.fileUrl + scope.row.threeStepUrl"
                ><span>{{ $store.state.fileUrl }}</span
                ><span>{{ scope.row.threeStepUrl }}</span></a
              >
              <span v-else>{{ scope.row.threeStepUrl }}</span>
            </template>
          </el-table-column>
          <el-table-column label="民事案件处理">
            <template slot-scope="scope">
              <a
                v-if="scope.row.fourStepUrl && scope.row.fourStepUrl !== '无'"
                :href="$store.state.fileUrl + scope.row.fourStepUrl"
              >
                <span>{{ $store.state.fileUrl }}</span
                ><span>{{ scope.row.fourStepUrl }}</span>
              </a>
              <span v-else>{{ scope.row.fourStepUrl }}</span>
            </template>
          </el-table-column>
          <el-table-column label="民事一审判决书">
            <template slot-scope="scope">
              <a
                v-if="scope.row.fiveStepUrl && scope.row.fiveStepUrl !== '无'"
                :href="$store.state.fileUrl + scope.row.fiveStepUrl"
                ><span>{{ $store.state.fileUrl }}</span
                ><span>{{ scope.row.fiveStepUrl }}</span></a
              >
              <span v-else>{{ scope.row.fiveStepUrl }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {
  sourceDataList,
  MeasurementData,
  deleteFiles,
  pretreatmentData,
  pretreatmentData2,
  pretreatmentData3,
  pretreatmentData4,
  saveDatabase
} from "@/api/dataList";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      value: "",
      MeasurementList: [],
      total: 0,
      saveLs:[],
      state:{
        "0":'未测算',
         1:"正在测算",
         2:"测算完成",
         3:"正在入库",
         4:"入库完成"
      }
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.saveLs = val;
      this.MeasurementList = val.map((item) => {
        return item.id;
      });
      //   this.multipleSelection = val;
    },
    // 删除文件
    deleteFile() {
      if (this.MeasurementList.length > 0) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const res = await deleteFiles(JSON.stringify(this.MeasurementList));
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getData();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message("请选择需要删除的文件！");
      }
    },
   async saveDatabase(){
      if(this.saveLs.length<1){
        this.$message("请选择需要存库的数据！");
         return 
      }

      // if(this.saveLs.length>15){
      //   this.$message("最多选择15条数据");
      //    return 
      // }
      const savaArr = [];
      for(const li of this.saveLs){
          if(li.status === 3 || li.status === 4){
            continue;
          }else{
            if(li.fiveStepUrl && li.fiveStepUrl!=='无'){
            savaArr.push({id:li.id,multipartFile:this.$store.state.fileUrl +  li.fiveStepUrl})
            }
          }
      }
     const ret = await saveDatabase(savaArr);
     if(ret.code ===200){
       this.$message({
            message: "操作成功！正在入库中,请耐心等待...",
            type: "success",
          });
     }
    },
    // 点击测算
    async Measurement() {
      if (this.MeasurementList.length > 0) {
        this.loading = true;
        const res = await MeasurementData(JSON.stringify(this.MeasurementList));
        if (res.code === 200) {
          this.$message({
            message: "操作成功！正在测算中,请耐心等待...",
            type: "success",
          });
          setTimeout(() => {
            this.getData();
            this.loading = false;
          }, 2000);
        }
      } else {
        this.$message("请选择需要测算的数据！");
      }
    },
    // 获取源文件列表
    async getData() {
      const res = await sourceDataList({ search: this.value });
      if (res.code === 200) {
        this.tableData = res.data.data;
        this.total = res.data.total;
      }
    },
    async handleCommand(val) {
      let res = {};
      if (this.MeasurementList.length > 0) {
        this.loading = true;
        if (val === "a") {
          res = await pretreatmentData(JSON.stringify(this.MeasurementList));
        } else if (val === "b") {
          res = await pretreatmentData2(JSON.stringify(this.MeasurementList));
        } else if (val === "c") {
          res = await pretreatmentData3(JSON.stringify(this.MeasurementList));
        } else {
          res = await pretreatmentData4(JSON.stringify(this.MeasurementList));
        }
        if (res.code === 200) {
          this.$message({
            message: "操作成功！正在测算中,请耐心等待...",
            type: "success",
          });
          setTimeout(() => {
            this.getData();
            this.loading = false;
          }, 2000);
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
          this.getData();
          this.loading = false;
        }
      } else {
        this.$message("请选择需要测算的数据！");
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
/* a{
  color: black;
} */
</style>